import React, { Component, useRef } from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import LightboxModal from "./modal"
import LightboxCarousel from "./carousel"
import { animated, useTrail, useChain, config } from "react-spring"
import { Waypoint } from "react-waypoint"

class LightboxController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
      revealed: false,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }
  reveal = () => {
    this.setState({ revealed: true })
  }

  render() {
    return (
      <>
        <section className="section__team" id="portfolio">
          <StaticQuery
            query={graphql`
              query {
                wpgraphql {
                  cptBiographies(
                    last: 20
                    where: { orderby: { field: MENU_ORDER, order: ASC } }
                  ) {
                    nodes {
                      title
                      id
                      acf_biography {
                        title
                        biography
                        contactButton
                        email
                      }
                      featuredImage {
                        node {
                          altText
                          sourceUrl
                          imageFile {
                            childImageSharp {
                              fluid(quality: 80) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              const nodes = data.wpgraphql.cptBiographies.nodes

              const trailRef = useRef()
              const trail = useTrail(nodes.length, {
                ref: trailRef,
                from: { opacity: 0, transform: "translate(0px,100px)" },
                to: {
                  opacity: this.state.revealed ? 1 : 0,
                  transform: this.state.revealed
                    ? "translate(0px,0px)"
                    : "translate(0px,100px)",
                },
                config: config.stiff,
                //  delay: 500
              })

              useChain(this.state.revealed ? [trailRef] : [trailRef])

              return (
                <>
                  <Waypoint bottomOffset="25%" onEnter={this.reveal} />
                  <section className="team-member__cards">
                    {trail.map(({ ...animation }, i) => (
                      <animated.figure
                        className="team-member__card"
                        onClick={this.handlePortfolioClick.bind(this, i)}
                        style={{ ...animation }}
                      >
                        <Img
                          imgStyle={{ objectPosition: "top" }}
                          className="team-member__photo"
                          alt={nodes[i].title}
                          fluid={
                            nodes[i].featuredImage.node.imageFile
                              .childImageSharp.fluid
                          }
                        />
                        <figcaption className="team-member__text mb-2 mb-sm-3 mb-md-2">
                          <h2 className="team-member__name pt-1">
                            {nodes[i].title}
                          </h2>
                          <div className="team-member__title">
                            {nodes[i].acf_biography.title}
                          </div>
                        </figcaption>
                      </animated.figure>
                    ))}
                    <LightboxModal
                      show={this.state.modalShow}
                      onHide={() => this.setModal(false, 0)}
                    >
                      <LightboxCarousel
                        items={data.wpgraphql.cptBiographies.nodes}
                        current={this.state.modalCurrent}
                      />
                    </LightboxModal>
                  </section>
                </>
              )
            }}
          />
        </section>
      </>
    )
  }
}

export default LightboxController
