import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from "reactstrap"
import Img from "gatsby-image"

const LightboxCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(props.current)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex =
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const slides = props.items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Container fluid={true}>
          <Row>
            <Col
              sm={{ size: 12 }}
              md={{ size: 4, offset: 1 }}
              lg={{ size: 3, offset: 2 }}
              className="p-0 pb-4"
            >
              <div className="modal_carousel">
                {item.featuredImage != null && (
                  <Img
                    className="team-modal__photo"
                    fluid={
                      item.featuredImage.node.imageFile.childImageSharp.fluid
                    }
                  />
                )}
              </div>
            </Col>
            <Col
              sm={{ size: 12 }}
              md={{ size: 7 }}
              lg={{ size: 6 }}
              className="pl-0 pl-md-2 pl-lg-4"
            >
              <h2 className="team-modal__name heading2">{item.title}</h2>
              <p className="team-modal__title">{item.acf_biography.title}</p>
              <p className="body-copy pb-3">{item.acf_biography.biography} </p>
              {item.acf_biography.biography.length < 100 && (
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                </p>
              )}

              {item.acf_biography.email != null && (
                <a
                  className="btn btn-outline-secondary"
                  href={`mailto:${item.acf_biography.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.acf_biography.contactButton || "Contact"}
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </CarouselItem>
    )
  })

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
    >
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  )
}

export default LightboxCarousel
