import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

const LightboxModal = props => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(props.onHide)
  console.log(props.show)

  return (
    <div>
      <Modal
        size="lg"
        isOpen={props.show}
        toggle={toggle}
        className="team-member__modal"
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>{props.children}</ModalBody>
      </Modal>
    </div>
  )
}

export default LightboxModal
